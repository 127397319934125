import { createTheme, ThemeOptions } from '@mui/material/styles';

const pseTheme = {
  palette: {
    mode: 'light',
    primary: {
      main: '#006671',
      dark: '#003c4b',
    },
    secondary: {
      main: '#f50057',
    },
    text: {
      primary: '#4a4a4a',
      secondary: '#717171',
    },
    error: {
      main: '#c41f3d',
      dark: '#fcf3f5',
    },
    success: {
      main: '#b8c42e',
      dark: '#eefae9',
    },
    info: {
      main: '#00a6cf',
      dark: '#eaf8fb',
    },
    warning: {
      main: '#ed9f1b',
      dark: '#fdf4e4',
    },
  },
  typography: {
    fontSize: 16,
    h1: {
      fontSize: 32,
      fontWeight: 700,
    },
    h2: {
      fontSize: 24,
      fontWeight: 700,
    },
    h3: {
      fontSize: 20,
      fontWeight: 700,
    },
    h4: {
      fontSize: 16,
      fontWeight: 700,
    },
    h5: {
      fontSize: 14,
      fontWeight: 700,
    },
    h6: {
      fontSize: 12,
      fontWeight: 700,
    },
    button: {
      fontSize: 20,
      lineHeight: 1.5,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          'backgroundColor': '#197ee3',
          'color': '#fff',
          'borderRadius': 20,
          '&:hover': {
            backgroundColor: '#004A94',
          },
        },
      },
    },
  },
};

export const theme = createTheme(pseTheme);
