import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import User from '../layouts/User';

const ProtectedRoutes = () => {
  const auth = useAuth();

  return auth.isAuthenticated ? <User /> : <Navigate to='/login' />;
};

export default ProtectedRoutes;
