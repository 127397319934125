import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import questions from '../assets/data/quizQuestions.json';
import { Stack } from '@mui/material';

const Quiz = ({ callback }) => {
  const [curQuestIndex, setCurQuestIndex] = useState(0);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('Choose the correct answer');

  const handleRadioChange = (event) => {
    setHelperText(' ');
    setError(false);
    handleSubmit(event.target.value);
  };

  const handleSubmit = (value) => {
    if (value && value === questions[curQuestIndex].answer) {
      setError(false);
      if (curQuestIndex >= questions.length - 1) {
        setHelperText('Quiz complete!');
        callback();
      } else {
        setHelperText(`You got it! Displaying Question #${curQuestIndex + 2}`);
        setCurQuestIndex(curQuestIndex + 1);
      }
    } else if (value && value !== questions[curQuestIndex].answer) {
      setHelperText('Sorry, wrong answer!');
      setError(true);
    } else {
      setHelperText('Please select an option.');
      setError(true);
    }
  };

  // on page load, shuffle quiz questions
  useEffect(() => {
    setQuizQuestions(shuffle(questions));
  }, []);

  // on questions change OR quizQuestions changes, shuffle question options
  useEffect(() => {
    // only shuffle options if quizQuestions exist
    if (quizQuestions && quizQuestions.length > 0) {
      setOptions(shuffle(quizQuestions[curQuestIndex].options));
    }
  }, [curQuestIndex, quizQuestions]);

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  // show loading animation if quizQuestions is blank
  if (quizQuestions.length <= 0)
    return (
      <Box>
        <CircularProgress />
      </Box>
    );

  return (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={2}
    >
      <Typography variant='h1'>Video Quiz</Typography>
      <Grid
        container
        spacing={2}
        justifyContent='center'
        alignItems='center'
        component='form'
      >
        <Grid
          item
          container
          xs={12}
          spacing={2}
          justifyContent='center'
          alignItems='center'
          component={FormControl}
          error={error}
          variant='standard'
        >
          <Grid
            item
            container
            xs={12}
            component={FormLabel}
            direction='column'
            justifyContent='center'
            alignItems='center'
            sx={{ bgcolor: '#F6F6F6', textAlign: 'center', p: 1, mb: 2 }}
          >
            <Typography variant='body1'>
              {`Question #${curQuestIndex + 1}`}
            </Typography>
            <Typography variant='body2'>
              {`${quizQuestions[curQuestIndex].question}`}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            component={RadioGroup}
            spacing={2}
            aria-labelledby='demo-error-radios'
            name='quiz'
            sx={{ mb: 2 }}
            onChange={handleRadioChange}
          >
            <Grid item xs={6}>
              <FormControlLabel
                value={options[0]}
                control={<Radio />}
                label={options[0]}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value={options[1]}
                control={<Radio />}
                label={options[1]}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value={options[2]}
                control={<Radio />}
                label={options[2]}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value={options[3]}
                control={<Radio />}
                label={options[3]}
              />
            </Grid>
          </Grid>
          <Grid item component={FormHelperText} sx={{ color: '#ED9F1B' }}>
            {helperText}
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

Quiz.propTypes = {
  data: PropTypes.shape({
    question: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
  }),
  key: PropTypes.number,
  callback: PropTypes.func,
};

export default Quiz;
