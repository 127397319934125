// import logo from './logo.svg';
// import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { theme } from './context/themeContext';
import Login from './pages/Login';
import ProtectedRoutes from './components/ProtectedRoute';
import { AuthProvider } from './context/authContext';
import CssBaseline from '@mui/material/CssBaseline';
import TrainingOrientation from './pages/TrainingOrientation';
import Landing from './pages/Landing';
import { registerLicense } from '@syncfusion/ej2-base';
import './App.css';
import '@syncfusion/ej2-icons/styles/material.css';

registerLicense(
  'ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5adEdhWX9Wc3VdQWVU'
);
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route element={<ProtectedRoutes />}>
              <Route
                path='/training-orientation'
                element={<TrainingOrientation />}
              />
              <Route path='/Landing' element={<Landing />} />
              <Route path='*' element={<Landing />} />
            </Route>
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
