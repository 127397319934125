import axios from 'axios';
import dayjs from 'dayjs';

/**
 * formats raw data and sends post request to api
 * @param {array} myArray - array of raw
 */
export const postCrewItems = async (arr, regId) => {
  let url = new URL('/userList', process.env.REACT_APP_API);
  // convert item to array if it isn't one
  let myArray = Array.isArray(arr) ? arr : new Array(arr);

  // create array with mapped data
  let myObjs = myArray.map((obj, ind) => {
    let { firstName, lastName, email, phone, role } = obj;
    const timestamp = dayjs();

    return {
      foreigncrew: `PSEFC${timestamp
        .add(ind, 'ms')
        .format('YYYYMMDDHHmmssSSS')}`,
      registrationId: `${regId}-${ind + 1}`,
      firstName,
      lastName,
      email,
      phone,
      completedOrientation: true,
      role,
      submittedBy: regId,
    };
  });

  // create array of post requests
  const myPosts = myObjs.map(async (obj) => {
    console.log(url.href);
    const response = await axios.post(url.href, obj);
    return response;
  });

  // wait for all post requests to complete
  return await axios.all(myPosts).then(
    axios.spread((...responses) => {
      console.log(responses);
    })
  );
};
